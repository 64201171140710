<template>
  <div class="Examine">
    <div>
      <el-select v-if="$store.state.Login.Jurisdiction == 0" size="medium" v-model="Factory" style="width: 110px; padding: 0px 0px 0px 10px;" clearable :placeholder="$t('system.选择厂区')">
        <el-option
          v-for="item in $PublicJs.factory"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-select size="medium" v-model="Department" style="width: 150px; padding: 0px 0px 0px 10px;" clearable :placeholder="$t('system.选择部门')">
        <el-option
          v-for="item in $PublicJs.department"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-input size="medium" clearable v-model="Text" style="width: 250px; padding: 0px 10px 0px 10px;" @keyup.enter.native="Butsele()" :placeholder="$t('All.品名') + '/' + $t('All.工单号')+ '/' + $t('All.系统单号') + '/' + $t('All.实验名称')"></el-input>
      <el-button type="primary" icon="el-icon-search" size="small" @click="Butsele()">{{$t('All.搜索')}}</el-button>
    </div>
    <div>
      <template>
        <el-table :data="tableData" style="width: 100%">
          <el-table-column align="center" type="index" width="60">
          </el-table-column>
          <el-table-column align="center" sortable prop="Date" :label="this.$t('system.创建日期')" width="160">
          </el-table-column>
          <el-table-column align="center" prop="Numbers" :label="$t('All.通知单号')" width="150">
          </el-table-column>
          <el-table-column align="center" prop="Gongdan" :label="$t('All.工单号')" width="100">
          </el-table-column>
          <el-table-column align="center" prop="Results" sortable :label="$t('All.判定')" width="90">
            <template slot-scope="scope">
              <el-tag
              effect="dark"
              :type="scope.row.Results === 'Fail'? 'danger' : ''"
              disable-transitions>{{scope.row.Results}}</el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="Product_item" :label="$t('All.品名') + '/' + $t('All.料号')">
          </el-table-column>
          <el-table-column align="center" prop="Experiment_name" sortable :label="$t('All.实验名称')" style="white-space: nowrap;">
          </el-table-column>
          <el-table-column align="center" prop="Urgent_state" sortable :label="$t('All.紧急状态')" width="110">
            <template slot-scope="scope">
              <el-tag
              effect="dark"
              :type="scope.row.Urgent_state === $t('All.特急') ? 'danger' : scope.row.Urgent_state === $t('All.急') ?'warning': ''"
              disable-transitions>{{ $t(`All.${scope.row.Urgent_state}`) }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="Receivername" sortable :label="$t('All.接单人')">
          </el-table-column>
          <el-table-column align="center" prop="Department" sortable :label="$t('All.部门')">
          </el-table-column>
          <el-table-column align="center" prop="Applicantname" sortable :label="$t('All.申请人')" width="90">
          </el-table-column>
          <el-table-column align="center" prop="Statename" sortable :label="this.$t('system.状态')">
            <template slot-scope="scope">
              {{ $t(`All.${scope.row.Statename}`) }}
            </template>
          </el-table-column>
          <el-table-column align="center"  :label="this.$t('system.操作')" width="160" fixed="right">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="Testflow(scope.row)">View</el-button>
              <el-button v-if="$store.state.Login.Jurisdiction == 0" size="mini" type="danger" @click="Laboratrydel(scope.row)">Del</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>
    <el-pagination
      style="text-align: center;"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageindex"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="page_size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  data () {
    return {
      Noitem: '',
      tableData: [],
      total: 0,
      page_size: 10,
      pageindex: 1,
      Text: '',
      Factory: '',
      Department: ''
    }
  },
  created () {
    this.Lagetflowany()
  },
  props: ['Addtab', 'RemoveTab', 'Renewtargename'],
  mounted () {

  },
  methods: {
    ...mapMutations(['Testdata', 'TabComponentFun']),
    async Lagetflowany () {
      const intfs = {
        Pageindex: this.pageindex,
        PageSize: this.page_size,
        UserData: this.$store.state.Login,
        Text: this.Text,
        Factory: this.Factory,
        Department: this.Department
      }
      const { data: res } = await this.$http.post('/api/Laboratory/Lagetflowany', intfs)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.total = res.response.TotalCount
      this.tableData = res.response.Laboratorydata
    },
    async Butsele () {
      this.pageindex = 1
      this.page_size = 10
      this.Lagetflowany()
    },
    async Laboratrydel (row) {
      const { data: res } = await this.$http.post('/api/Laboratory/Laboratrydel', row)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.Lagetflowany()
    },
    Setquer () {
      this.pageindex = 1
      this.Lagetflowany()
    },
    async Testflow (row) {
      this.Testdata(row)
      this.Addtab(row.Numbers, 'Testflow')
    },
    handleSizeChange (val) {
      this.page_size = val
      this.Lagetflowany()
    },
    handleCurrentChange (val) {
      this.pageindex = val
      this.Lagetflowany()
    }
  }
}
</script>
<style lang="less" scoped>
/** talbe表头置顶**/
/deep/ .el-table {
    overflow: visible;
 }

 /deep/ .el-table__header-wrapper {
    position: sticky;
    top: 0px;
    z-index: 10;
 }
</style>
